<template>
    <font-awesome-icon
        icon="info-circle"
        @click="toDetalhar()"
        size="lg"
        class="icon-button p-text-primary"
        title="Detalhar"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-01')"
    />
</template>

<script>
export default {
    props: {
        convenio: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
                this.$router.push({
                    name: 'Rm_Convenios_ExecucaoDireta_Detalhar_Gestor',
                    params: {
                        id: this.convenio.idCnt,
                    },
                });
                return;
            }

            this.$router.push({
                name: 'Rm_Convenios_ExecucaoDireta_Detalhar',
                params: {
                    id: this.convenio.idCnt,
                },
            });
        },
    },
};
</script>
