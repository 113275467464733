<template>
    <painel titulo="Convênios de Execução Direta" icone="handshake" :refreshFunction="atualizar" v-show="mostrarListagem">
        <erros-box :fromBus="true"></erros-box>
        <filtro @filtrar="filtrar($event)"></filtro>
        <tabela
            :data="convenios"
            dataKey="idCnt"
            :globalFilterFields="['convenio', 'convenioParceiro', 'fornecedor', 'objeto', 'status']"
            stateKey="dt-rm-convenios-execucao-direta"
        >
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :convenio="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="convenio" header="Convênio" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.convenio }}
                    </template>
                </Column>
                <Column field="convenioParceiro" header="Convênio Parceiro" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.convenioParceiro }}
                    </template>
                </Column>
                <Column field="fornecedor" header="Parceiro Cedente" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.fornecedor }}
                    </template>
                </Column>
                <Column field="objeto" header="Objeto" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.objeto }}
                    </template>
                </Column>
                <Column field="status" header="Status" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.status }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import Filtro from './Filtro';
import ConveniosServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        Filtro,
        BtnDetalhar,
    },

    data() {
        return {
            convenios: null,
            filtro: null,
        };
    },

    computed: {
        mostrarListagem() {
            return this.$route.name == 'Rm_Convenios_ExecucaoDireta' || this.$route.name == 'Rm_Convenios_ExecucaoDireta_gestor';
        },
    },

    methods: {
        filtrar(filtro) {
            this.filtro = filtro;

            if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
                this.obterConveniosPorPerfilGestor();
                return;
            }

            this.obterConvenios();
        },

        obterConvenios() {
            this.$store.dispatch('addRequest');
            ConveniosServices.obterConvenios(this.filtro).then((response) => {
                if (response?.success) {
                    this.convenios = response.data;
                } else {
                    this.$store.dispatch('setErros', response.errors);
                    this.convenios = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterConveniosPorPerfilGestor() {
            this.$store.dispatch('addRequest');

            ConveniosServices.obterConveniosPorPerfilGestor(this.filtro).then((response) => {
                if (response?.success) {
                    this.convenios = response.data;
                } else {
                    this.$store.dispatch('setErros', response.errors);
                    this.convenios = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            if (!this.filtro) return;

            if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
                this.obterConveniosPorPerfilGestor();
                return;
            }

            this.obterConvenios();
        },
    },
};
</script>
