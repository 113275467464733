<template>
    <div class="formgrid grid p-fluid mt-2">
        <div class="field col-12 md:col-2">
            <label>Convênio</label>
            <InputMask v-model="convenio" mask="aa.9999.99" />
        </div>
        <div class="field col-12 md:col-3">
            <label>Status</label>
            <Dropdown v-model="status" :options="statusConvenios" optionLabel="descricao" :filter="true"></Dropdown>
        </div>
        <div class="field col-12 md:col-3 lg:col-2">
            <label>Data Assinatura (DE)</label>
            <Calendar v-model="dataAssinaturaDe" :manualInput="false" :maxDate="dataAssinaturaAte" :showButtonBar="true" />
        </div>
        <div class="field col-12 md:col-3 lg:col-2">
            <label>Data Assinatura (ATÉ)</label>
            <Calendar v-model="dataAssinaturaAte" :manualInput="false" :minDate="dataAssinaturaDe" :showButtonBar="true" />
        </div>
        <div class="field col-12 md:col-3">
            <label>Entidade Cedente</label>
            <Dropdown v-model="fornecedor" :options="fornecedores" optionLabel="nome" :filter="true"></Dropdown>
        </div>
        <div class="field col-12 md:col-6">
            <label>Objeto</label>
            <InputText type="text" v-model="objeto" />
        </div>
        <div class="field col-12 md:col-2 pt-1">
            <Button label="Pesquisar" icon="pi pi-search" @click="pesquisar()" class="mt-4" />
        </div>
    </div>
</template>

<script>
import ConveniosServices from './services';

export default {
    data() {
        return {
            statusConvenios: [],
            fornecedores: [],
            convenio: null,
            status: null,
            dataAssinaturaDe: null,
            dataAssinaturaAte: null,
            fornecedor: null,
            objeto: null,
        };
    },

    computed: {
        filtro() {
            return {
                convenio: this.convenio ? this.convenio.replace('/', '-') : '',
                status: this.status ? this.status.codStatus : '',
                fornecedor: this.fornecedor ? this.fornecedor.codCfo : '',
                objeto: this.objeto ? this.objeto : '',
                dataAssinaturaDe: this.dataAssinaturaDe ? this.$dateFormat(this.dataAssinaturaDe, 'YYYY-MM-DD') : '',
                dataAssinaturaAte: this.dataAssinaturaAte ? this.$dateFormat(this.dataAssinaturaAte, 'YYYY-MM-DD') : '',
            };
        },
    },

    methods: {
        pesquisar() {
            this.$emit('filtrar', this.filtro);
        },

        obterFiltrosOpcoes() {
            this.$store.dispatch('addRequest');
            ConveniosServices.obterFiltrosOpcoes().then((response) => {
                if (response?.success) {
                    this.statusConvenios = response.data.statusConvenios;
                    this.statusConvenios.unshift({ codStatus: '', descricao: 'TODOS' });
                    this.status = this.statusConvenios[0];

                    this.fornecedores = response.data.fornecedores;
                    this.fornecedores.unshift({ codCfo: '', nome: 'TODOS' });
                    this.fornecedor = this.fornecedores[0];
                } else {
                    this.$store.dispatch('setErros', response.errors);
                    this.statusConvenios = [];
                    this.fornecedores = [];
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterFiltrosOpcoesGestor() {
            this.$store.dispatch('addRequest');

            ConveniosServices.obterFiltrosOpcoesGestor().then((response) => {
                if (response?.success && !response?.data) {
                    this.statusConvenios = [];
                    this.fornecedores = [];
                    this.$store.dispatch('removeRequest');
                    return;
                }

                if (response?.success) {
                    this.statusConvenios = response.data.statusConvenios;
                    this.statusConvenios.unshift({ codStatus: '', descricao: 'TODOS' });
                    this.status = this.statusConvenios[0];

                    this.fornecedores = response.data.fornecedores;
                    this.fornecedores.unshift({ codCfo: '', nome: 'TODOS' });
                    this.fornecedor = this.fornecedores[0];
                } else {
                    this.$store.dispatch('setErros', response.errors);
                    this.statusConvenios = [];
                    this.fornecedores = [];
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    created() {
        if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
            this.obterFiltrosOpcoesGestor();
            return;
        }
        this.obterFiltrosOpcoes();
    },
};
</script>
